/* ========================== */
/* Global settings */
@import url("https://fonts.googleapis.com/css2?family=Iceland&family=Permanent+Marker&family=Rock+Salt&display=swap");

body {
  font-family: "Iceland", cursive;
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 0;
}

.VideoPlayerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.vidPlayer {
  width: 60%;
  border: 3px solid #424242;
}
.BacktoDesigns {
  margin-top: 1%;
}

.BacktoDesigns a {
  font-size: 2em;
  text-decoration: none;
  color: #81f7ff;
}

.BacktoDesigns a:hover {
  color: #fff;
}

/* all Pages != homePage */
.containerBody {
  height: 100vh;
}

body::-webkit-scrollbar {
  display: none;
}

/* navigation on all pages */
.siteNavRow {
  font-size: 2.5em;
  height: 6vh;
}

.siteNavCol .active a {
  color: #fff;
}

.MobileMenuCanvas .active a {
  color: #81f7ff;
}

.designheaderRow {
  padding-top: 1%;
  height: 94vh;
}

.logoBrandCol {
  color: #81f7ff;
}
.brandLogo {
  width: 35px;
  height: 35px;
}

.siteNavCol > ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

.siteNavRow a {
  color: #81f7ff;
  text-decoration: none;
}

.siteNavRow a:hover {
  color: #fff;
}

/* mobile ham menu */

.MobileMenuCanvas ul {
  list-style-type: none;
}

.MobileMenuCanvas ul li {
  border-bottom: 1px solid #81f7ff;
  padding-bottom: 10px;
}

.MobileMenuCanvas ul li a {
  list-style-type: none;
  text-decoration: none !important;
  color: #dadada;
  font-size: 3em;
}

/* scroll bar  */
/* width */
::-webkit-scrollbar {
  width: auto;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
  border-radius: 100%;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
  border: 1px solid #81f7ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #81f7ff;
}

/* sizing of navigation on large screens */

@media screen and (min-width: 1280px) {
  .siteNavRow {
    font-size: 2.2em;
  }
}

@media screen and (min-width: 1680px) {
  .siteNavRow {
    font-size: 2.8em;
  }
}

@media screen and (min-width: 2360px) {
  .siteNavRow {
    font-size: 4em;
  }
}

@media screen and (min-width: 2560px) {
  .siteNavRow {
    font-size: 4em;
  }
}

/* removes ham menu from large screens */
@media screen and (min-width: 979px) {
  .HamMenuCol {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .containerBody {
    height: 140vh;
  }
}
@media screen and (max-width: 979px) {
  .containerBody {
    height: 220vh;
  }
}

/* Controls the off canvas menu on mobile */
@media screen and (max-width: 769px) {
  .containerBody {
    height: 105vh;
  }
  .logoBrandCol {
    color: #81f7ff;
    font-size: 1.3em;
  }

  .MobileMenuCanvas ul {
    list-style-type: none;
  }

  .MobileMenuCanvas ul li {
    border-bottom: 1px solid #81f7ff;
    padding-bottom: 10px;
  }

  .MobileMenuCanvas ul li a {
    list-style-type: none;
    text-decoration: none !important;
    color: #dadada;
    font-size: 2em;
  }
}

@media screen and (max-width: 480px) {
  .MobileMenuCanvas ul li a {
    font-size: 1.5em;
  }
  .logoBrandCol {
    color: #81f7ff;
    font-size: 1.2em;
  }
}

@media screen and (max-width: 415px) {
  .logoBrandCol {
    font-size: 0.8em !important;
    margin-top: 1%;
  }
}

@media screen and (max-width: 414px) {
  .logoBrandCol {
    font-size: 0.7em;
    margin-top: 3%;
  }
}
