.exContainer {
  transition: transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  background: url("../../images/ExPagePics/resumeBackground.png") top center
    repeat;
  animation: backanim 45s linear infinite;
  -webkit-animation: backanim 45s linear infinite;
}

@keyframes backanim {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: 1280px 1220px;
  }
}

@-webkit-keyframes backanim {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: 1280px 1220px;
  }
}

/* Experience body section container*/
.exBodyRow {
  font-size: 1.5em;
  height: 85vh;
  margin-top: 2%;
}

.exBackletterE {
  background: url("../../images/ExPagePics/E-Letter.png") center / 80% 100%
    no-repeat;
}

.exBackletterS {
  background: url("../../images/ExPagePics/S-Letter.png") center / 80% 100%
    no-repeat;
}
.tabSection {
  padding-top: 1%;
  height: 80vh;
}

.mb-3 {
  border-bottom: 1px solid #787878;
  font-size: 1.8em;
  display: flex;
  justify-content: center;
}
.nav-tabs .nav-link {
  background: 0 0;
  border-radius: 5px;
  color: #81f7ff;
  margin: 0 10px;
  padding: 0 10px;
}

.nav-tabs .nav-link:hover {
  border: 1px solid #fff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #dadada;
  background-color: transparent;
  border-color: #81f7ff;
  margin-bottom: 10%;
}

/* contains all experience page subsections, children */
.exRow {
  margin: 0;
  overflow-y: hidden;
}

.exNavRow {
  margin-top: 2%;
}

.exHeader {
  display: flex;
  justify-content: center;
}

.exLeftCol {
  margin-top: 2%;
  padding: 20px;
  position: relative;
}

.exRightCol {
  margin-top: 2%;
  padding: 20px;
  position: relative;
}

.exInnerDiv {
  text-align: right;
  width: auto;
}

.exInnerDivRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.1em;
}

.exInnerDivRight a {
  text-align: center;
  text-decoration: none;
  color: #81f7ff;
}

.exInnerDivRight ul {
  list-style-type: square;
}

.exInnerDivRight ul li {
  margin-bottom: 2%;
}
.louPort {
  width: 35%;
  border: 1px solid #fff;
  border-radius: 5px;
  text-align: center;
}
.view-resume {
  display: block;
  border: 1px solid #fff;
  color: #fff !important;
  background-color: #6e6e6e;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
}

/* subpages tabs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #81f7ff;
  background-color: transparent;
}

.tabSection .nav-item {
  margin-bottom: 1%;
}
.tabSection .nav-item a {
  color: #dadada;
  background-color: transparent;
  border: none;
  border-radius: 5px;
}
.tabSection .nav-item a:hover {
  color: #81f7ff;
}

.tabBodyBtnCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tabBodyBtnCol .hideArrow {
  visibility: hidden;
}

.tabBodyBtnCol .nav-link.active {
  color: #81f7ff !important;
  background-color: #424242;
  border: 1px solid #81f7ff;
}
.RowBorder {
  border-bottom: 1px solid #5b5b5b;
}

.jobTitle span {
  display: inline-block;
  color: #dadada;
  
}
.jobTitle span:nth-child(1) {
  color: #81f7ff;
}

/* skills section  */
.progressbardiv {
  margin-bottom: 3%;
}

.progressRightDiv,
.progressLeftDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.progress {
  --bs-progress-height: 100%;
  --bs-progress-font-size: 1.1em;
  --bs-progress-bg: transparent;
  --bs-progress-border-radius: 5px;
  border: 2px solid #888888;
  --bs-progress-bar-color: #000;
  --bs-progress-bar-bg: #81f7ff;
  --bs-progress-bar-transition: width 5.6s linear;
}

.headerCol {
  display: flex;
  justify-content: center;
}
.headerCol button {
  color: #81f7ff;
  background-color: transparent;
  border: 2px solid #81f7ff;
  font-size: 1.2em;
  padding: 0 10px;
  margin-bottom: 2%;
}

.headerCol button:hover {
  color: #81f7ff;
  background-color: #424242 !important;
  border: 2px solid #81f7ff;
  font-size: 1.2em;
  padding: 0 10px;
  margin-bottom: 2%;
}

/* This is the education section */

.EdCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
}

.innerDiv {
  text-align: center;
  margin: 1% 0;
}

.innerDiv p span:nth-child(1) {
  color: #81f7ff;
  font-size: 1.4em;
}

/* large screens */

@media screen and (max-width: 2561px) {
  .exBodyRow {
    font-size: 2em;
  }
}

@media screen and (max-width: 2361px) {
  .exBodyRow {
    font-size: 2.4em;
  }
}

@media screen and (max-width: 1920px) {
  .exBodyRow {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 1680px) {
  .exInnerDivRight{
    font-size: 1em;
  }
}

@media screen and (max-width: 1440px) {
  .exInnerDivRight{
    font-size: .9em;
  }
}

@media screen and (max-width: 1366px) {
  .exBodyRow {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 1280px) {
  .exBodyRow {
    font-size: 1.3em;
  }
}


/* ============================== */
/* small screens  */

@media screen and (max-width: 1025px) {

  .exBodyRow {
    font-size: 1.6em;
  }
  .jobTitle  {
    font-size: 1.2em;
  }
  .exInnerDivRight{
    font-size: 1.3em;
  }
  .exBackletterE {
    background: url("../../images/ExPagePics/E-Letter.png") top / 100% 60%
      no-repeat;
  }

  .exBackletterS {
    background: url("../../images/ExPagePics/S-Letter.png") top / 100% 100%
      no-repeat;
  }
}

@media screen and (max-width: 979px) {

  .exBodyRow {
    font-size: 1em;
  }
  .jobTitle  {
    font-size: .9em;
  }
}

@media screen and (max-width: 768px) {

  .exBodyRow {
    font-size: 1em;
  }
  .jobTitle  {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 481px) {

  .exBodyRow {
    font-size: 1em;
  }
  .jobTitle  {
    font-size: 1em;
  }
  .mb-3 {
    font-size: 1em;
  }

  .exInnerDivRight {
    font-size: 1em;
  }
}

@media screen and (max-width: 481px) {

  .exBodyRow {
    font-size: 1em;
  }
  .jobTitle  {
    font-size: 1em;
  }
  .mb-3 {
    font-size: 1em;
  }

  .exInnerDivRight {
    font-size: 1em;
  }
}


@media screen and (max-width: 320px) {

  .exBodyRow {
    font-size: .9em;
    margin-top: 10%;
  }
  .jobTitle  {
    font-size: .8em;
  }
  .mb-3 {
    font-size: 1em;
  }

  .exInnerDivRight {
    font-size: .9em;
  }
}
