.designsContainer {
  background: url("../../images/mb_background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bannerCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* ==================================== */
/* banner header */

.bannerCol > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #81f7ff;
  font-size: 8em;
  font-family: "Rock Salt", cursive;
  letter-spacing: 8px;
}

.bannerCol > div > span:nth-child(1) {
  animation-name: fadeTitle;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}

@keyframes fadeTitle {
  0% {
    opacity: 0%;
    transform: translate3d(-10px, 0, 0);
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
      0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  100% {
    opacity: 100%;
    transform: translate3d(0, 0, 0);
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

/* ===================================== */
/* glow animation */

.miamiGlow {
  -webkit-animation: glow 20s ease-in-out infinite alternate;
  -moz-animation: glow 20s ease-in-out infinite alternate;
  animation: glow 20s ease-in-out infinite alternate;
}

.subHeader {
  line-height: 50px;
  font-size: 0.15em;
  animation: fadeTitle;
  animation-duration: 5s;
}

/* ========================================= */
/* scroll down dot animation */
.dotDiv {
  display: flex;
  justify-content: center;
  width: 20px;
  height: 40px;
  margin-top: 2%;
  border: 2px solid #858585;
  border-radius: 5px;
  animation: fadeTitle;
  animation-duration: 5s;
  animation-timing-function: ease-in;
}

.dotDivHide {
  visibility: hidden;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #858585;
  border-radius: 100%;
  position: relative;
  animation: dotMove 1s linear 0s infinite alternate;
  -moz-animation: dotMove 1s linear 0s infinite alternate;
  -o-animation: dotMove 1s linear 0s infinite alternate;
  -webkit-animation: dotMove 1s linear 0s infinite alternate;
}

@keyframes dotMove {
  0% {
    top: 50%;
  }
  25% {
    top: 70%;
  }
  50% {
    top: 30%;
  }
  75% {
    top: 20%;
  }
  100% {
    top: 10%;
  }
}
@-moz-keyframes dotMove {
  0% {
    top: 50%;
  }
  25% {
    top: 70%;
  }
  50% {
    top: 30%;
  }
  75% {
    top: 20%;
  }
  100% {
    top: 10%;
  }
}

@-o-keyframes dotMove {
  0% {
    top: 50%;
  }
  25% {
    top: 70%;
  }
  50% {
    top: 30%;
  }
  75% {
    top: 20%;
  }
  100% {
    top: 10%;
  }
}

@-webkit-keyframes dotMove {
  0% {
    top: 50%;
  }
  25% {
    top: 70%;
  }
  50% {
    top: 30%;
  }
  75% {
    top: 20%;
  }
  100% {
    top: 10%;
  }
}

/* =================================== */
/* Design Slider */

#horizontalScroller {
  /* margin: 0;
  padding: 0; */
  flex-wrap: nowrap;
  overflow: hidden;
}

#slideButtonL {
  position: absolute;
  top: 148%;
  left: 10px;
}

#slideButtonR {
  position: absolute;
  top: 150%;
  right: 10px;
}

#slideButtonL,
#slideButtonR {
  z-index: 2;
  background-color: #000;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #81f7ff;
  color: #81f7ff;
  font-size: 25px;
  font-weight: bold;
}

#slideDivRec {
  height: 100vh;
  margin: 0;
  padding: 0;
}

.divbox {
  height: 50%;
  overflow: hidden;
  border: 1px solid #81f7ff;
}

.imageDiv img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  /* filter: brightness(150%); */
  opacity: 0.6;
  transition: 0.3s;
}

.imageDiv img:hover {
  filter: brightness(100%);
  opacity: 1;
  transform: scale(1.2);
}

/* Controls the text of the sliders */
.SliderTitleDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #81f7ff;
  padding: 10px 50px 0;
}

.SliderTitleDiv .number {
  text-decoration: underline;
  font-size: 2em;
}

.SliderTitleDiv .description {
  font-size: 1.5em;
  text-align: center;
}

.SliderTitleDiv .SliderTitle {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  height: auto;
  font-size: 1.7em;
  letter-spacing: 3px;
}

/* mobile screens */

@media screen and (max-width: 2561px) {
  /* Controls the text of the sliders */
  .SliderTitleDiv {
    padding: 10px 50px 0;
  }

  .SliderTitleDiv .number {
    font-size: 2.5em;
  }

  .SliderTitleDiv .description {
    font-size: 2.3em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 2361px) {
  .SliderTitleDiv {
    padding: 10px 50px 0;
  }

  .SliderTitleDiv .number {
    font-size: 2.5em;
  }

  .SliderTitleDiv .description {
    font-size: 2.3em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 1920px) {
  .SliderTitleDiv {
    padding: 10px 30px 0;
  }

  .SliderTitleDiv .number {
    font-size: 2em;
  }

  .SliderTitleDiv .description {
    font-size: 1.8em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 1.6em;
  }
}

@media screen and (max-width: 1440px) {
  .SliderTitleDiv {
    padding: 10px 20px 0;
  }

  .SliderTitleDiv .number {
    font-size: 1.7em;
  }

  .SliderTitleDiv .description {
    font-size: 1.5em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 1500px) {
  .bannerCol > div:nth-child(1) {
    font-size: 6.5em;
  }
}

@media screen and (max-width: 1366px) {
  .SliderTitleDiv {
    padding: 10px 20px 0;
  }

  .SliderTitleDiv .number {
    font-size: 1.4em;
  }

  .SliderTitleDiv .description {
    font-size: 1.2em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 1em;
  }
}

@media screen and (max-width: 1281px) {
  .bannerCol > div:nth-child(1) {
    font-size: 5.5em;
  }
}

@media screen and (max-width: 1200px) {
  .bannerCol > div:nth-child(1) {
    font-size: 4.5em;
  }
  .subHeader {
    font-size: 0.3em;
  }

  .slideContainer {
    height: auto;
  }

  #slideButtonL,
  #slideButtonR {
    display: none;
  }

  #slideDivRec {
    height: auto;
    display: flex;
    flex-direction: row;
  }

  .SliderTitleDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5% 0 0 5%;
    height: 100%;
  }

  .SliderTitleDiv .description {
    text-align: left;
    font-size: 1.6em;
  }

  .SliderTitleDiv .SliderTitle {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    font-size: 2em;
  }

  /* image and titles boxes */
  #slideDivRec {
    height: auto;
    margin: 0;
    padding: 0;
  }

  #horizontalScroller {
    flex-wrap: wrap;
  }

  .divbox {
    width: 50%;
    height: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .SliderTitleDiv {
    padding: 10px 20px 0;
  }

  .SliderTitleDiv .number {
    font-size: 3em;
  }

  .SliderTitleDiv .description {
    font-size: 1.7em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 1.6em;
  }
}

@media screen and (max-width: 769px) {
  .bannerCol > div:nth-child(1) {
    font-size: 3em;
  }

  .SliderTitleDiv .description {
    font-size: 1.4em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 481px) {
  .SliderTitleDiv {
    padding: 10px 20px 0;
  }

  .SliderTitleDiv .number {
    font-size: 1.8em;
  }

  .SliderTitleDiv .description {
    font-size: 1.2em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 1em;
  }
  .bannerCol > div:nth-child(1) {
    font-size: 2em;
  }


}

@media screen and (max-width: 415px) {
  .SliderTitleDiv .number {
    font-size: 1.4em;
  }
  .bannerCol > div:nth-child(1) {
    font-size: 1.5em;
  }

  .subHeader {
    font-size: 0.5em;
    text-align: center;
  }

  .SliderTitleDiv .description {
    font-size: 0.9em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 0.9em;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 391px) {
  .bannerCol > div:nth-child(1) {
    font-size: 1.2em;
  }

  .subHeader {
    font-size: 0.5em;
    text-align: center;
  }
  .SliderTitleDiv .description {
    font-size: 0.9em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 0.8em;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 321px) {
  .bannerCol > div:nth-child(1) {
    font-size: 1em;
  }

  .subHeader {
    font-size: 0.5em;
    text-align: center;
  }

  .SliderTitleDiv .description {
    font-size: 0.6em;
  }

  .SliderTitleDiv .SliderTitle {
    font-size: 0.7em;
    letter-spacing: 1px;
  }
  .SliderTitleDiv .number {
    font-size: 1.3em;
  }
}
