.storyBody {
  background: url("../../images/StoryPics/giphy.gif") center / 100% 100%
    no-repeat;
}

.storyBodyRow {
  font-size: 1.5em;
  margin-top: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.storyBodyRow:nth-child(1n) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.storyBodyRow .card {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
}

.cardImgDiv {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
}

.storyBodyRow .cardImgDiv img {
  width: 100%;
  height: 45vh;
  border-radius: 40px;
  padding: 10px;
  opacity: 0.9;
}

.storyBodyRow .textDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22vh;
}

.textDivInner {
  padding: 10px 15px 5px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  width: 100%;
}

@media screen and (max-width: 2561px) {
  .storyBodyRow {
    font-size: 1.8em;
  }
  .storyBodyRow .cardImgDiv img {
    width: 90%;
  }

  .textDivInner {
    width: 90%;
  }
}

@media screen and (max-width: 2361px) {
  .storyBodyRow {
    font-size: 2em;
  }
  .storyBodyRow .cardImgDiv img {
    width: 100%;
    height: 38vh;
  }
}

@media screen and (max-width: 1920px) {
  .storyBodyRow {
    font-size: 1.4em;
  }
  .storyBodyRow .cardImgDiv img {
    height: 42vh;
  }
}

@media screen and (max-width: 1440px) {
  .storyBodyRow {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 1366px) {
  .storyBodyRow {
    font-size: 1.2em;
  }
  .storyBodyRow .cardImgDiv img {
    width: 90%;
    height: 45vh;
  }
}

@media screen and (max-width: 1280px) {
  .storyBodyRow {
    font-size: 1.2em;
  }

  .textDivInner {
    width: 85%;
  }
}

@media screen and (max-width: 1024px) {
  .storyBody {
    background: url("../../images/StoryPics/giphy.gif") center / 130% 100%
      no-repeat;
  }

  .storyBodyRow {
    margin-top: 5%;
  }
  .storyBodyRow .cardImgDiv img {
    width: 90%;
    height: 38vh;
  }
  .storyBodyRow {
    font-size: 2em;
  }

  .textDivInner {
    width: 80%;
  }
}

@media screen and (max-width: 979px) {
  .storyBody {
    background: url("../../images/StoryPics/giphy.gif") center / 100% 100%
      no-repeat;
  }

  .storyBodyRow {
    margin-top: 5%;
  }
  .storyBodyRow .cardImgDiv img {
    width: 50%;
    height: 60vh;
  }
  .storyBodyRow {
    font-size: 1.2em;
  }

  .card {
    margin-top: 1%;
  }

  .textDiv {
    margin: 4% 0;
  }
  .textDivInner {
    width: 50%;
  }
}

@media screen and (max-width: 769px) {
  .storyBodyRow {
    margin-top: 1%;
  }
  .storyBodyRow .cardImgDiv img {
    width: 70%;
    height: 30vh;
  }
  .storyBodyRow {
    font-size: 1.5em;
  }

  .textDiv {
    margin: -5% 0;
  }
  .textDivInner {
    width: 95%;
  }
}

@media screen and (max-width: 481px) {
  .storyBodyRow .textDiv {
    height: auto;
  }

  .storyBodyRow {
    font-size: 1em;
  }

  .textDiv {
    margin: 1% 0;
  }
  .textDivInner {
    width: 95%;
  }
}

@media screen and (max-width: 481px) {
  .storyBodyRow {
    margin-top: 5%;
  }
  .storyBodyRow .textDiv {
    height: auto;
  }

  .storyBodyRow {
    font-size: 1em;
  }

  .textDiv {
    margin: 1% 0;
  }
  .textDivInner {
    width: 95%;
  }
}


@media screen and (max-width: 481px) {
  .storyBodyRow {
    margin-top: 5%;
  }
  .storyBodyRow .textDiv {
    height: auto;
  }

  .storyBodyRow {
    font-size: .9em;
  }

  .textDiv {
    margin: 1% 0;
  }
  .textDivInner {
    width: 95%;
  }
}

