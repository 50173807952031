.contactBody {
  /* background: url("../../images/ContactPics/new_york_city_neon_cityscape.jpg")
    center / 100% 100% no-repeat; */
    background-color: #000;
}

.ContactRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  margin-top: 2%;
}

.contactBody {
  overflow: hidden;
}
.ContactRow:nth-child(1n) {
  height: auto;
  text-align: center;
}

.glow {
  font-size: 7em;
  color: #fff;
  text-align: center;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
      0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.Message {
  background-color: #000;
  border: 2px solid #ff4da6;
  color: #ff4da6;
  border-radius: 5px;
  position: absolute;
  width: 15%;
  right: 40px;
  top: 10%;
  padding: 1% 0 0.5% 0;
  line-height: 10px;
  animation: fade-in-right 1s ease-in-out;
  animation-fill-mode: both;
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.formCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formDivContain {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 10px;
}

::placeholder {
  color: #81f7ff;
  font-size: 35px;
}
::-webkit-input-placeholder {
  color: #81f7ff;
}

:-ms-input-placeholder {
  color: #81f7ff;
}

input[type="text"],
input[type="email"],
textarea {
  color: #dadada;
  width: 100%;
  margin: 3% 0 8%;
  display: inline-block;
  border: none;
  border-bottom: 1px solid #81f7ff;
  background-color: transparent;
  box-sizing: border-box;
}

textarea {
  height: 100px;
}

.no-outline:focus {
  outline: none;
}

.contactForm button {
  background-color: transparent;
  color: #81f7ff;
  width: 70%;
  font-size: 1em;
  padding: 10px 0 5px;
  border-radius: 5px;
  border: 1px solid #81f7ff;
}

.socialMediaCol {
  width: 80%;
  margin-top: 5%;
  display: flex;
  justify-content: space-evenly;
}



@media screen and (min-width: 2360px) {
  .glow {
    font-size: 5em;
  }
  .formDivContain {
    height: auto;
  }
  ::placeholder {
    font-size: 45px;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    margin: 8% 0 8%;
  }
}
@media screen and (min-width: 2560px) {
  .glow {
    font-size: 7em;
  }
  .formDivContain {
    height: auto;
  }
  ::placeholder {
    font-size: 45px;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    margin: 4% 0 4%;
  }
}

@media screen and (max-width: 1366px) {
  .glow {
    font-size: 4em;
  }
  ::placeholder {
    font-size: 25px;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    margin: 3% 0 2%;
  }
}

@media screen and (max-width: 1025px) {
  .contactBody {
    /* background: url("../../images/ContactPics/new_york_city_neon_cityscape.jpg")
      center / 150% 100% no-repeat; */
      background-color: #000;
  }
  .glow {
    font-size: 7em;
  }

  .formCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 55vh;
    width: 75%;
    padding: 0px;
  }

  .formDivContain {
    width: 100%;
    height: 55vh;
  }

  .contactForm {
    width: 90%;
  }
  ::placeholder {
    font-size: 40px;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    margin: 8% 0 8%;
  }
}

@media screen and (max-width: 979px) {
  .glow {
    font-size: 3em;
  }

  ::placeholder {
    font-size: 22px;
  }

  .formCol {
    height: auto;
  }
  .formDivContain {
    width: 70%;
    height: auto;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    margin: 2% 0 2%;
  }

  textarea {
    height: 80px;
  }
  .contactForm button {
    font-size: 0.7em;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .contactBody {
    /* background: url("../../images/ContactPics/new_york_city_neon_cityscape.jpg")
      center / 170% 110% no-repeat; */
      background-color: #000;
  }
  .glow {
    font-size: 5em;
  }
  ::placeholder {
    color: #81f7ff;
    font-size: 35px;
  }
  .formCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 55vh;
    width: 90%;
  }
  .formDivContain {
    width: 100%;
    padding: 10px;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    margin: 2% 0 8%;
  }
  .contactForm button {
    width: 70%;
    font-size: 1em;
    height: auto;
  }
  .socialMediaCol {
    width: 80%;
  }
}

@media screen and (max-width: 481px) {
  .contactBody {
    /* background: url("../../images/ContactPics/new_york_city_neon_cityscape.jpg")
      center / 270% 110% no-repeat; */
      background-color: #000;
  }
  .glow {
    font-size: 3em;
  }
  ::placeholder {
    font-size: 25px;
  }
  .formCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
    width: 90%;
  }

  .formDivContain {
    width: 100%;
    height: 65vh;
    padding: 10px;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    margin: 2% 0 8%;
  }
  .contactForm button {
    width: 70%;
    font-size: 0.6em;
    height: auto;
  }
  .socialMediaCol {
    width: 90%;
  }
  svg.social-svg {
    width: 40px !important;
    height: 40px !important;
  }
}

@media screen and (max-width: 414px) {
  .glow {
    font-size: 2.5em;
  }
  .formDivContain {
    width: 100%;
    height: 55vh;
    padding: 10px;
  }
  .contactForm button {
    width: 70%;
    font-size: 0.6em;
    margin-bottom: 2%;
    height: auto;
  }
  svg.social-svg {
    width: 30px !important;
    height: 30px !important;
  }
}

@media screen and (max-width: 390px) {
  .glow {
    font-size: 2.5em;
  }

  .formCol {
    height: 55vh;
  }
  .formDivContain {
    width: 100%;
    height: 50vh;
  }
}

@media screen and (max-width: 390px) {
  .contactHeader {
    margin-top: 6%;
  }
  .glow {
    font-size: 1.5em;
  }

  .formCol {
    height: 75vh;
  }
  .formDivContain {
    width: 100%;
    height: auto;
  }
  .contactForm button {
    width: 100%;
    height: auto;
  }
}
